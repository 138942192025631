import ascending_triangle from "./patterns/ascending_triangle.vue";
import bearish_flag from "./patterns/bearish_flag.vue";
import bearish_key_reversal from "./patterns/bearish_key_reversal.vue";
import bearish_pennant from "./patterns/bearish_pennant.vue";
import bullish_flag from "./patterns/bullish_flag.vue";
import bullish_key_reversal from "./patterns/bullish_key_reversal.vue";
import bullish_pennant from "./patterns/bullish_pennant.vue";
import descending_triangle from "./patterns/descending_triangle.vue";
import double_bottom from "./patterns/double_bottom.vue";
import double_top from "./patterns/double_top.vue";
import saucer_bottom from "./patterns/saucer_bottom.vue";
import tri_point_resistance from "./patterns/tri_point_resistance.vue";
import tri_point_support from "./patterns/tri_point_support.vue";

import bearish_engulfing from "./candle/bearish_engulfing.vue";
import bearish_harami from "./candle/bearish_harami.vue";
import bearish_harami_cross from "./candle/bearish_harami_cross.vue";
import bullish_engulfing from "./candle/bullish_engulfing.vue";
import bullish_harami from "./candle/bullish_harami.vue";
import bullish_harami_cross from "./candle/bullish_harami_cross.vue";
import doji from "./candle/doji.vue";
import hammer from "./candle/hammer.vue";
import hanging_man from "./candle/hanging_man.vue";
import inverted_hammer from "./candle/inverted_hammer.vue";
import shooting_star from "./candle/shooting_star.vue";
import inside_bar from "./candle/inside.vue";
import multiple_inside_bar from "./candle/multipleInside.vue";
import gt_multiple_inside_bar from "./candle/gtMultipleInside.vue";



export default [
    ascending_triangle,
    bearish_flag,
    bearish_key_reversal,
    bearish_pennant,
    bullish_flag,
    bullish_key_reversal,
    bullish_pennant,
    descending_triangle,
    double_bottom,
    double_top,
    saucer_bottom,
    tri_point_resistance,
    tri_point_support,
    
    
    // Candle Icons
    bearish_engulfing,
    bearish_harami,
    bearish_harami_cross,
    bullish_engulfing,
    bullish_harami,
    bullish_harami_cross,
    doji,
    hammer,
    hanging_man,
    inverted_hammer,
    shooting_star,
    inside_bar,
    multiple_inside_bar,
    gt_multiple_inside_bar
]