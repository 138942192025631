<template>
    <svg version="1.1" id="Layer_1" xmlns="&ns_svg;" xmlns:xlink="&ns_xlink;" width="35" height="19.985"
        viewBox="0 0 35 19.985" overflow="visible" enable-background="new 0 0 20 19.985" xml:space="preserve">
        
        <rect x="1" y="2.985" stroke="#000000" stroke-width="2" width="6" height="14" />
        <line class="no-fill" stroke="#000000" stroke-width="2" x1="4.004" y1="2" x2="4.004" y2="0" />
        <line class="no-fill" stroke="#000000" stroke-width="2" x1="3.996" y1="19.985" x2="3.996" y2="17.985" />
        <rect x="13" y="8.5" class="no-fill" stroke="#000000" stroke-width="2" width="6" height="7" />
        <line class="no-fill" stroke="#000000" stroke-width="2" x1="16" y1="7.5" x2="16" y2="5.5" />
        <line class="no-fill" stroke="#000000" stroke-width="2" x1="16" y1="18.5" x2="16" y2="16.5" />
        <rect x="25" y="12.5" class="no-fill" stroke="#000000" stroke-width="2" width="6" height="4" />
        <line class="no-fill" stroke="#000000" stroke-width="2" x1="28" y1="11.5" x2="28" y2="9.5" />
        <line class="no-fill" stroke="#000000" stroke-width="2" x1="28" y1="18.5" x2="28" y2="16.5" />

    </svg>
</template>
<script>
export default {
    name: "multiple_inside_bar",
    props: {
        color: {
            type: String,
            default: "#000000"
        }
    }
}
</script>